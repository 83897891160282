html,
body {
	height: 100%;
}

#root {
	height: 100%;
}

body {
	margin: 0;
	font-family: 'Work Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat'), url(./fonts/Montserrat-VariableFont_wght.ttf) format('truetype');
}
